import {
  alert,
  alertThresholds,
  utils,
} from '@decision-sciences/qontrol-common'
import cx from 'classnames'
import propTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'

// Hooks
import { PERMISSION_TYPES, PERMISSIONS, useAccess } from 'hooks/access'

// Utils
import { getColorMap } from 'components/panel-with-highlighted-content/utils'
import { getAlertTemplateById } from 'modules/alert-templates/actions'

// Constants
import { REAL_TRIGGER_FREQUENCY_TOOLTIP } from 'modules/alerts/alerts-thresholds-statistics/utils'

// Components
import Input from 'components/input'
import Loader from 'components/loader'
import Table from 'components/table/beta'
import { StaticTooltip } from 'components/tooltip'
import AlertFormulaSection from 'modules/alerts/alerts-thresholds-statistics/alert-formula-section/index'
import { AssociatedAlerts } from 'modules/alerts/alerts-thresholds-statistics/associated-alerts/index'
import { AssociatedAlertsTable } from 'modules/alerts/alerts-thresholds-statistics/associated-alerts/table'
import { PerformanceReferenceTable } from 'modules/alerts/alerts-thresholds-statistics/performance-reference-table'
import { TriggerFrequencyRealData } from 'modules/alerts/alerts-thresholds-statistics/trigger-frequency/trigger-frequency-real-data'
import {
  ThresholdRecommendationCell,
  ThresholdRecommendationHeader,
} from 'modules/alerts/alerts-thresholds-statistics/threshold-recommendations/cell'
import { BulkAssignRecommendationsModal } from 'modules/alerts/alerts-thresholds-statistics/threshold-recommendations/modal'

// Icons
import { ReactComponent as InfoIcon } from 'assets/icon_info.svg'

import '../style.scss'

const { isEmpty } = utils.object

const { ALERT_GRANULARITY } = alert
const { STATISTICS_LOCATION } = alertThresholds

const AlertsThresholdStatistics = ({
  alert,
  triggerValues,
  emitModifiedThresholds,
  company,
  isAlertThresholdsLinked,
  parentClient,
  simplifiedView,
  alertTriggerId,
  selectedEntity,
  accountId,
  elementType,
}) => {
  const canView = useAccess([
    {
      feature: PERMISSIONS.ALERT_THRESHOLDS,
      type: PERMISSION_TYPES.READ,
    },
    {
      feature: PERMISSIONS.ALERT_THRESHOLD_DATA_FORM,
      type: PERMISSION_TYPES.READ,
    },
  ])
  const canEdit = useAccess({
    feature: PERMISSIONS.ALERT_THRESHOLDS,
    type: PERMISSION_TYPES.EDIT,
  })
  const isViewMode = canView && (!canEdit || isAlertThresholdsLinked)

  const [alertThresholds, setAlertThresholds] = useState([])
  const [modifiedThresholds, setModifiedThresholds] = useState({})
  const [recommendedThresholds, setRecommendedThresholds] = useState({})
  const [recommendedThresholdsLoading, setRecommendedThresholdsLoading] =
    useState({})
  const [recommendedThresholdsPerMetric, setRecommendedThresholdsPerMetric] =
    useState({})
  const [formula, setFormula] = useState('')
  const [loading, setLoading] = useState(false)
  const [bulkAssignModalVisible, setBulkAssignModalVisible] = useState(false)
  const [hoveredSampleDataWordIndex, setHoveredSampleDataWordIndex] =
    useState(null)

  // Inherit alert thresholds from parent if necessary
  const companyThresholds = isAlertThresholdsLinked
    ? parentClient.alertThresholds
    : company.alertThresholds

  const hasAlertGranularity =
    alert?.granularity && alert?.granularity !== ALERT_GRANULARITY.OTHER

  /**
   * Map recommended threshold: metric type -> threshold
   * Set loaders for each threshold
   * Don't recommend if:
   *     - there is no metric type set on the threshold
   *     - the threshold represents a percentage
   * @param {Array<Object>} alertThresholds list of thresholds
   * @param {Object} recommendations threshold recommendations per metric type
   */
  const updateRecommendedThresholds = (
    alertThresholds,
    recommendations = {}
  ) => {
    if (!alertThresholds.length) {
      setRecommendedThresholdsPerMetric((existingRecommendationsPerMetric) => ({
        ...existingRecommendationsPerMetric,
        ...recommendations,
      }))

      return
    } else if (!alert?.time) {
      return
    }

    recommendations = { ...recommendations, ...recommendedThresholdsPerMetric }

    if (!Object.keys(recommendations).length) {
      return
    }

    const newRecommendedThresholdsLoading = {}
    const newThresholdRecommendations = alertThresholds.reduce(
      (acc, { _id, metric, percentage }) => {
        const value = recommendations?.[metric]

        if (!metric || percentage) {
          newRecommendedThresholdsLoading[_id] = false

          return {
            ...acc,
            [_id]: null,
          }
        }

        newRecommendedThresholdsLoading[_id] =
          recommendedThresholds[_id] || recommendedThresholds[_id] === 0
            ? false
            : !value && value !== 0

        return {
          ...acc,
          [_id]:
            recommendedThresholds[_id] || recommendedThresholds[_id] === 0
              ? recommendedThresholds[_id]
              : value,
        }
      },
      {}
    )

    setRecommendedThresholds((existingRecommendations) => ({
      ...existingRecommendations,
      ...newThresholdRecommendations,
    }))
    setRecommendedThresholdsLoading((existingRecommendationsLoading) => ({
      ...existingRecommendationsLoading,
      ...newRecommendedThresholdsLoading,
    }))
  }

  useEffect(() => {
    if (emitModifiedThresholds) {
      emitModifiedThresholds(modifiedThresholds)
    }
  }, [modifiedThresholds])

  const alertThresholdValues = useMemo(() => {
    return alertThresholds.reduce(
      (acc, threshold) => ({
        ...acc,
        [threshold.key]: !isEmpty(modifiedThresholds[threshold._id])
          ? modifiedThresholds[threshold._id]
          : threshold.value,
      }),
      {}
    )
  }, [JSON.stringify(alertThresholds), modifiedThresholds])

  useEffect(() => {
    if (alert && alert.template && !alertThresholds.length) {
      const {
        template: { _id },
      } = alert
      setLoading(true)
      getAlertTemplateById(_id, STATISTICS_LOCATION.TRIGGER_PAGE)
        .then((result) => {
          const { thresholds, templateFormula } = result
          if (thresholds) {
            setAlertThresholds(thresholds)
            if (alert?.time && hasAlertGranularity) {
              setRecommendedThresholdsLoading(
                thresholds.reduce(
                  (acc, { _id }) => ({ ...acc, [_id]: true }),
                  {}
                )
              )
            }

            updateRecommendedThresholds(alertThresholds)
          }
          if (templateFormula) {
            setFormula(templateFormula)
          }
          setLoading(false)
        })
        .catch((e) => {
          setLoading(false)
          console.error(e)
        })
    }
  }, [alert, JSON.stringify(alertThresholds)])

  // Initialise modified thresholds
  useEffect(() => {
    const alertThresholdsIds = alertThresholds.map(({ _id }) => _id)
    const clientThr = companyThresholds.some(({ globalThreshold }) =>
      alertThresholdsIds.includes(globalThreshold)
    )
    if (!Object.keys(modifiedThresholds).length && clientThr) {
      const modified = {}
      alertThresholds.forEach((thr) => {
        const clientThr = companyThresholds.find(
          ({ globalThreshold }) => globalThreshold === thr._id
        )
        if (clientThr) {
          modified[thr._id] = clientThr.value
        }
      })
      setModifiedThresholds(modified)
    }
  }, [JSON.stringify(companyThresholds), JSON.stringify(alertThresholds)])

  const wordsToHighlight = alertThresholds.map((thr) => `[${thr.key}]`)
  const colorMap = getColorMap(wordsToHighlight)

  const tooltipAlerts = Object.values(
    alertThresholds.reduce((prev, current) => {
      current.alerts.forEach((alert) => {
        if (prev[alert._id]) {
          prev[alert._id].thresholdsUsed += alert.thresholdsUsed
        } else {
          prev[alert._id] = structuredClone(alert)
        }
      })
      return prev
    }, {})
  )

  const onThresholdChange = (newValue, _id) => {
    setModifiedThresholds((currentModifiedThresholds) => ({
      ...currentModifiedThresholds,
      [_id]: newValue,
    }))
  }

  const onBulkThresholdChange = (overwrite) => {
    Object.entries(recommendedThresholds).forEach(([key, value]) => {
      const clientThreshold = modifiedThresholds[key]
      if (overwrite) {
        if (value) {
          onThresholdChange(value.toString(), key)
        }
      } else {
        if (!clientThreshold && value) {
          onThresholdChange(value.toString(), key)
        }
      }
    })
    changeBulkAssignModalVisibility(false)()
  }

  const changeBulkAssignModalVisibility = (visible) => () => {
    setBulkAssignModalVisible(visible)
  }

  const columns = [
    {
      header: 'Alert Threshold',
      id: 'name',
      accessorKey: 'name',
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 160,
    },
    {
      header: 'Alert Threshold Key',
      id: 'key',
      cell: (cell) => (
        <span
          style={{ backgroundColor: colorMap[`[${cell.row.original.key}]`] }}
        >
          {cell.row.original.key}
        </span>
      ),
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 200,
    },
    {
      header: 'Global',
      id: 'global-threshold',
      cell: (cell) => (
        <span
          className={
            modifiedThresholds[cell.row.original._id]
              ? 'alert-thresholds-panel__default'
              : 'font-bold'
          }
        >
          {new Intl.NumberFormat('en-US').format(cell.row.original.value)}
        </span>
      ),
      textAlign: 'right',
      cellTextAlign: 'right',
      size: 96,
    },
    {
      header: 'Client',
      id: 'client-threshold',
      size: 96,
      cell: (cell) => {
        const { _id } = cell.row.original
        const value = modifiedThresholds[_id] || null
        if (isViewMode) {
          return (
            <span className="font-bold">{value ? value.toString() : '--'}</span>
          )
        }
        return (
          <Input
            className={cx('client-threshold', {
              'client-threshold--completed':
                (typeof value === 'number' || value) && !isViewMode,
            })}
            placeholder=" -- "
            onBlur={(ev) => onThresholdChange(ev.target.value, _id)}
            value={value?.toString()}
            type="budget"
            disabled={isViewMode}
            min={0}
          />
        )
      },
      textAlign: 'right',
      cellTextAlign: 'right',
    },
    {
      header: () => {
        return (
          <ThresholdRecommendationHeader
            alertThresholds={alertThresholds}
            clientThresholds={modifiedThresholds}
            recommendedThresholds={recommendedThresholds}
            openBulkAssignModal={changeBulkAssignModalVisibility(true)}
          />
        )
      },
      textAlign: 'right',
      id: 'recommended-threshold',
      size: 118,
      // TODO [Titus]: Actual logic when AP-11619 is implemented
      hidden: true,
      cell: (cell) => {
        return (
          <ThresholdRecommendationCell
            threshold={cell.row.original}
            clientThresholds={modifiedThresholds}
            recommendedThresholds={recommendedThresholds}
            recommendedThresholdsLoading={recommendedThresholdsLoading}
            onThresholdChange={onThresholdChange}
          />
        )
      },
    },
    {
      header: () => {
        return (
          <div className="display-flex gap-8 align-items-center associated-alerts__info-icon">
            Other Alerts
            <StaticTooltip
              tooltipClassName="associated-alerts__tooltip"
              content={<AssociatedAlertsTable alerts={tooltipAlerts} />}
            >
              <InfoIcon />
            </StaticTooltip>
          </div>
        )
      },
      id: 'alerts',
      cell: ({
        row: {
          original: { alerts },
        },
      }) => <AssociatedAlerts alerts={alerts} />,
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 130,
      maxSize: 130,
    },
  ]

  if (loading) {
    return <Loader />
  }

  return (
    <div className="alert-threshold-modal">
      {bulkAssignModalVisible && (
        <BulkAssignRecommendationsModal
          visible={bulkAssignModalVisible}
          onAssign={onBulkThresholdChange}
          onClose={changeBulkAssignModalVisibility(false)}
        />
      )}
      <AlertFormulaSection
        simulated={false}
        fullText={formula}
        highlights={wordsToHighlight}
        colorsMap={colorMap}
        pattern={/((?:\[[^[\]]*\])|(?:[+-]*\s*[0-9]+\s*[+-]*)|(?:[()]))/g}
        separator={' '}
        hoveredWordIndex={hoveredSampleDataWordIndex}
        setHoveredWordIndex={setHoveredSampleDataWordIndex}
        alertThresholdValues={alertThresholdValues}
        triggerValues={triggerValues}
        isSimplifiedViewMode={simplifiedView}
      />
      <Table
        columns={columns}
        data={alertThresholds || []}
        className={'thresholds-table margin-bottom-16'}
      />
      <div className="align-row">
        <TriggerFrequencyRealData
          header="Trigger Frequency"
          tooltipContent={REAL_TRIGGER_FREQUENCY_TOOLTIP}
          accountId={accountId}
          alertTriggerId={alertTriggerId}
          companyId={company._id}
          elementType={elementType}
          selectedEntity={selectedEntity}
        />
        <PerformanceReferenceTable
          onRoadmap
          alert={alert}
          alertThresholds={alertThresholds}
          companyId={company._id}
          recommendedThresholdsLoading={recommendedThresholdsLoading}
          updateRecommendedThresholds={updateRecommendedThresholds}
        />
      </div>
    </div>
  )
}

AlertsThresholdStatistics.propTypes = {
  alert: propTypes.object.isRequired,
  triggerValues: propTypes.object,
  emitModifiedThresholds: propTypes.func.isRequired,
  company: propTypes.object.isRequired,
  parentClient: propTypes.object,
  isAlertThresholdsLinked: propTypes.bool,
  simplifiedView: propTypes.bool,
  alertTriggerId: propTypes.string,
  selectedEntity: propTypes.string,
  accountId: propTypes.string,
  elementType: propTypes.string,
}

export default AlertsThresholdStatistics
