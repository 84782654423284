import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

/* Hooks */
import { useOnClickOutside } from 'hooks/outside-click'

/* Components */
import Icon from 'components/icon/index'
import Button from 'components/button/index'
import { DropdownList } from 'components/dropdown-list'
import { RadioNoHooks } from 'components/radio/index'

/* Assets */
import { ReactComponent as CheckIcon } from 'assets/icon_check_no_border.svg'
import { ReactComponent as CopyIcon } from 'assets/icon_duplicate.svg'

/**
 * Controls copy settings from client or BU
 * Displays the controls for doing so.
 * @param {Object} params React Params
 * @param {Object} params.company Bu Object
 * @param {Object} params.parentCompany Client Object
 * @param {Function} params.onChangeCopyCompany Handler invoked when client or BU is selected
 * @param {Boolean} params.disabled True if the control should be disabled in UI
 * @returns {Node}
 */
export const CopyControl = ({
  company,
  parentCompany,
  onChangeCopyCompany,
  disabled = true,
}) => {
  const [listOpened, setListOpened] = useState(false)
  const [selectedEntity, setSelectedEntity] = useState(null)
  const [initialCompany, setInitialCompany] = useState()
  const ref = useRef()
  const iconRef = useRef()

  useEffect(() => {
    setInitialCompany(company)
  }, [])

  useEffect(() => {
    if (listOpened) {
      setSelectedEntity(null)
    }
  }, [listOpened])

  const openList = () => {
    setListOpened(true)
  }
  const closeList = () => {
    setListOpened(false)
  }

  useOnClickOutside(ref, closeList)

  const onConfirm = () => {
    onChangeCopyCompany(selectedEntity?.[0])
    setListOpened(false)
  }

  const onChange = (value) => {
    setSelectedEntity([value])
  }

  const availableBUs = (parentCompany?.businessUnits || [])
    .filter((bu) => !bu.new && bu._id !== initialCompany?._id)
    .map(({ _id, name }) => ({
      label: name,
      value: _id,
    }))

  return (
    <div className="client-link-control client-copy-control" ref={ref}>
      {listOpened && (
        <div className="client-link-control__popover">
          <div className="client-link-control__popover__heading">
            COPY SETTINGS
          </div>
          <div className="client-link-control__popover__title">Client</div>
          <div className="client-link-control__popover__section">
            <RadioNoHooks
              checked={
                selectedEntity?.indexOf(parentCompany._id.toString()) > -1
              }
              className="blue"
              label={parentCompany.name}
              value={parentCompany._id.toString()}
              onChange={() => {
                onChange(parentCompany._id.toString())
              }}
            />
          </div>
          {availableBUs?.length > 0 && (
            <>
              <div className="client-link-control__popover__title">
                BUSINESS UNIT
              </div>
              <DropdownList
                className="client-link-control__dropdown"
                options={availableBUs}
                selectedItems={[]}
                onChange={onChange}
                optionsHeight={400}
                hasSearch={availableBUs.length > 1}
                selectAll={availableBUs.length > 1}
                multiSelect={false}
                sortOptions={true}
                standalone={true}
                optionRenderer={({ label, value }) => (
                  <>
                    <RadioNoHooks
                      checked={selectedEntity?.indexOf(value) > -1}
                      className="blue"
                      label={label}
                    />
                  </>
                )}
              />
            </>
          )}
          <div className="client-link-control__popover__footer">
            <Button
              secondaryGreen
              compact
              disabled={!selectedEntity}
              onClick={onConfirm}
              value={
                <div className="display-flex gap-4 align-items-center uppercase font-size-11 bold">
                  <CheckIcon width={20} height={20} />
                  Save
                </div>
              }
            />
            <Button secondaryGray onClick={closeList} value="Cancel" />
          </div>
        </div>
      )}

      <Icon
        className="client-link-control__link"
        onClick={listOpened ? closeList : openList}
        disabled={disabled}
      >
        <CopyIcon ref={iconRef} width={20} height={20} className="svg--blue" />
      </Icon>
    </div>
  )
}

CopyControl.propTypes = {
  company: PropTypes.object.isRequired,
  parentCompany: PropTypes.object.isRequired,
  onChangeCopyCompany: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
